import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Templates`}</h1>
    <p>{`There are a few types of templates available on Dropbox. They are all available under `}<a parentName="p" {...{
        "href": "https://www.dropbox.com/sh/qrbu706uv6l9ue0/AADgJl2q6vim2E7tpDo5YNgDa?dl=0"
      }}><inlineCode parentName="a">{`Templates`}</inlineCode></a>{`. The goal of these templates is to help the Datastory team create and promote new products in a fast, easy and consistent way.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p"><em parentName="strong">{`Warning: Do not edit the templates directly.`}</em></strong></p>
    </blockquote>
    <h2>{`Promotional templates`}</h2>
    <p>{`Promotional templates will typically have a couple of artboards that are required, and some that are optional. Feel free to create more social media assets as you see fit.`}</p>
    <h3>{`How to work with app promotional templates`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make a new folder in `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/26jhnbmp6pvcfu6/AADYT8bPEjNxRhxfgMoxgIiBa?dl=0"
          }}><inlineCode parentName="a">{`Apps`}</inlineCode></a>{` with the same name as your app. Please use hyphenation (e.g. `}<inlineCode parentName="p">{`swedish-schools`}</inlineCode>{`). If a folder for your app already exists skip to step 2.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a folder called `}<inlineCode parentName="p">{`promotional`}</inlineCode>{` in the relevant app folder.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy `}<inlineCode parentName="p">{`app-promotional-template.sketch`}</inlineCode>{` from `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/forjo35pp96diuf/AABaTDOOu_lpX1BWzUYlPZDca?dl=0"
          }}><inlineCode parentName="a">{`Templates/Promotional templates`}</inlineCode></a>{` into the `}<inlineCode parentName="p">{`promotional`}</inlineCode>{` folder you created in step 2 and rename it to your app name.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The app promotional template includes 2 required resources:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">{`app icon`}</li>
      <li parentName="ul">{`app cover`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Tip: Your icon should fit in the dotted circle. This will account for paddings when the icons is displayed on an app card.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ad2a25de148c8f1d470b3946780fd1bc/a1bed/promotional-app-templates.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.52657004830918%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA8UlEQVQoz5WT2YqEQAxF/f+PFBUFH1xoy33L9AkdsZ0aZQLXpCrxZjEG8zxLlmUShqHEcay6LEuZpkmGYZBxHP+FgBfrulYS003TSN/30nWdtG2rwH4CBQTGvCyLbNt2gMoJgBiN/wkUp4Trusq+718gAJ9PLOZ8RigisOoQbLKYk/M1kY/4FyEG8no14t7zMifkFnzWVVXL/CnCS2jzU8elZR+hazsd05+E5oQUG/hafhIlpK00TSWKIkmS5N1OdZDZKEzOW3AFCfUr82DPnHOqWRPuAHdFURw7yvrcQRfbKjFAZHeQUDV/Up7nR6I7/AAKGlz276ty9gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Promotional app templates",
            "title": "Promotional app templates",
            "src": "/static/ad2a25de148c8f1d470b3946780fd1bc/f570d/promotional-app-templates.png",
            "srcSet": ["/static/ad2a25de148c8f1d470b3946780fd1bc/0783d/promotional-app-templates.png 240w", "/static/ad2a25de148c8f1d470b3946780fd1bc/782f4/promotional-app-templates.png 480w", "/static/ad2a25de148c8f1d470b3946780fd1bc/f570d/promotional-app-templates.png 960w", "/static/ad2a25de148c8f1d470b3946780fd1bc/8d0ff/promotional-app-templates.png 1440w", "/static/ad2a25de148c8f1d470b3946780fd1bc/a987b/promotional-app-templates.png 1920w", "/static/ad2a25de148c8f1d470b3946780fd1bc/a1bed/promotional-app-templates.png 2070w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`  This sketch file also includes an artboard that allows you to test how your icon and cover would look like. Here is an example of how your sketch file could look after you have filled in the artboards:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b23823851947857b7eb998e35bb7f7d3/8e724/promotional-app-templates-example.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.97222222222222%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABtklEQVQoz32SS0hUURjHL9hCl+HCbbWIWjg7F/ZaROvZZAUSEihESJCEFYlRNouIBN1E4iZNEgZtkyCjWEMMNdRCmEZGxEVG0VOb6L7OOffeX+fc6+QQ0Qd/zvnO4/c9zrF830cIRRCB4/jMThWZHi8wM1kkO/ma7KM3TI2/pLz8ngiF5wik0DMlkXJHSinNEVhSOoSVi7CRobq5RarlBnuarrFvVz97G/o42HiZ3dZ5RjM5jA0NTnN/+DFBELtEUUTNDNRSIYRrvbB6hp+bNodSd0l1TXDgVJaudJqHw810HD3MSCYfX1pfKVEqv9NZBTGspj9AoTekVLqcgG/fPY7vv8qds2lud57mXPcQY/kHpE8OcG9wnn9ZFP6Voemh5/lx/b9sl2KuRJB/QbC0RO75J2aX4cnCZ9YrH/D1uZtX5hjoe0p/zwTP5svboCRb00vLgITwMWCp58a8bemjWo6WNLnw5WOVttbrHDtygY4T7dy6lMXeirAdWzPkDjB56WR0XQ/fdWO5rva9ZM0ErdqCtcUCG4VXLJZ/UHn7NY4ZRmFdD+uA/1NcgRT13YulguTL1L7Nb5TVMRmjt7pxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Promotional app templates example",
            "title": "Promotional app templates example",
            "src": "/static/b23823851947857b7eb998e35bb7f7d3/f570d/promotional-app-templates-example.png",
            "srcSet": ["/static/b23823851947857b7eb998e35bb7f7d3/0783d/promotional-app-templates-example.png 240w", "/static/b23823851947857b7eb998e35bb7f7d3/782f4/promotional-app-templates-example.png 480w", "/static/b23823851947857b7eb998e35bb7f7d3/f570d/promotional-app-templates-example.png 960w", "/static/b23823851947857b7eb998e35bb7f7d3/8d0ff/promotional-app-templates-example.png 1440w", "/static/b23823851947857b7eb998e35bb7f7d3/a987b/promotional-app-templates-example.png 1920w", "/static/b23823851947857b7eb998e35bb7f7d3/8e724/promotional-app-templates-example.png 2880w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`How to work with topic promotional templates`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make a new folder in `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/htdsqs7ai527x6s/AACybdtf0UsOIL44RKdzTx_Ra?dl=0"
          }}><inlineCode parentName="a">{`Topics`}</inlineCode></a>{` with the same name as your topic. Please use hyphenation (e.g. `}<inlineCode parentName="p">{`swedish-media`}</inlineCode>{`). If a folder for your topic already exists skip to step 2.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a folder called `}<inlineCode parentName="p">{`promotional`}</inlineCode>{` in the relevant topic folder.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy `}<inlineCode parentName="p">{`topic-promotional-template.sketch`}</inlineCode>{` from `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/forjo35pp96diuf/AABaTDOOu_lpX1BWzUYlPZDca?dl=0"
          }}><inlineCode parentName="a">{`Templates/Promotional templates`}</inlineCode></a>{` into the `}<inlineCode parentName="p">{`promotional`}</inlineCode>{` folder you created in step 2 and rename it to your topic name.`}</p>
      </li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Tip: the dotted area on the cover artboard indicates the approximate area that will be visible when the image will be shared on some social media channels. Make sure that all essential visuals fit in the dotted rectangle.`}</p>
    </blockquote>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`The template contains artboards for 3 required resources: `}</li>
    </ol>
    <ul>
      <li parentName="ul">{`1 horizontal topic illustration`}</li>
      <li parentName="ul">{`1 square topic illustration `}</li>
      <li parentName="ul">{`1 topic cover* (add topic illustration on a colored background)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/034c856f35d6dd92dcb24190295b6105/8e724/promotional-topic-templates-example.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.77777777777778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABvUlEQVQoz1WRPWgUQRTHB63s7EQEK7GyUPHrMBJNKitBbRIQFI800cJKLEQEQYiYaCs2ShpBxASSImhiche9M0qCEC0iiXL5OFfd22xuv2Zn9ufbI4I++PHeMMz//948FUYRSRQS/1oharjgrKLr34njmLQZkCSQWoh9j6g0hK6Mkk0PY9+8QE/I+d0I+sMYujxEMjuJMlkGwuvPM7yfeYhXfUQidWwsZfcrgfsWY34TLy9h2xUUFC/vXWSivwtu7IHuHWSnt8FRhb20D5VZsZeoTI0z+Ow283MjmIU5XD/gyeBdSuNFPn55RVhbgs4tZJ1bedx3nbHZAeaX7zP1/AH27E44pjDFAyg2wxqDtyHzrX3Dri2SSuOfpicpVSukcp+sLJKdUqTnd1G9eZmB/luUn95hvecwnNlOdlwEew6iHMeh0WigtRbRtDWqthm+77Mufxg1m+hcsLYAbeJ/SOhQbJzbDUekbt9kv4x8YS/K87zW43wJLfIlSQ6CANd1xcxtmcT1GkFvG6GQXD0BVwqk104KHZic3gK6r4jKO8tJZJ1/yYXz/O9dGIas1n9Qd37iByFJvvnU/I9O+QMiINREBzEzCAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Topic template example",
            "title": "Topic template example",
            "src": "/static/034c856f35d6dd92dcb24190295b6105/f570d/promotional-topic-templates-example.png",
            "srcSet": ["/static/034c856f35d6dd92dcb24190295b6105/0783d/promotional-topic-templates-example.png 240w", "/static/034c856f35d6dd92dcb24190295b6105/782f4/promotional-topic-templates-example.png 480w", "/static/034c856f35d6dd92dcb24190295b6105/f570d/promotional-topic-templates-example.png 960w", "/static/034c856f35d6dd92dcb24190295b6105/8d0ff/promotional-topic-templates-example.png 1440w", "/static/034c856f35d6dd92dcb24190295b6105/a987b/promotional-topic-templates-example.png 1920w", "/static/034c856f35d6dd92dcb24190295b6105/8e724/promotional-topic-templates-example.png 2880w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`*The topic cover image is used as an og image, meaning that it will appear when sharing a link to a topic on social media:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c13c77ae362fadfbc5e0b86d2e9b1dcc/8d0ff/metatags-example.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.05555555555554%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAAC90lEQVQ4y32U3YtVZRTGz3+QwdCFWnhRN4H+A95IF4WEaQpTogMTdSddRKR5I2EQWJeKgTCGOULhB9iFUeaUwdxYzkw2go7TNN/Hczxf+/vzXT/Xu/ceZxzQDc95373etZ/1rI/31CaXhUbLIYxikpziEZFiDeOUxYddlpo9nCAr4IVZcV76CG3f0OiGeH5AlmXUgjAuyPLckCmCKMEUrpDrT5zmep4UPkZJrJ+pCC1SdbKBOl5KEOfUphs5jh8XiowxJG4PCVzwu2DXyEfU7rk+Sax+YqyjRssRVYTJ9FVUjAbTtfbD34bZuqPGrPiQO6N0p+/wqL5IdvcWTNwkjSOm2xHz3ZjUlkKRUpWHlU25q00sGJodVaGRRaMyfpPW2ChT/9wlujUCt0cwvoNcHya/MUxj9CfyX4fh5yHkl+8xapfAKYhtCWqqmyRV2WKz0TTm7sN//yIPxhUTyMID8m4T3u1j8f1XuXztFK3rnzD/9QC8/QKy9yVkeYaqZtSyqhnlu9ByQprdADcWPIWrZcpcreeBLTQP7eD8yePcuPYtjRMfwnubkYHXkIezZdaWMIoTPM/XGueF5FbH41HbpedFOG5YkBqnA/tfgT0v4g1uI9n3crFnt6J/E1KfWSVsd3qEYfRE4f+LLe7N1Jmr95hd7rLcDsm0uwtXz7FwZQjvtx/xRy7i/H5F10sEf17FhN6T+a05rqdK/JJQDVEqhNrCINaZTEyxZhqoo6n3FHGU4uq5p/2zMuzAmYqsIJRqWNdO/9OgVPDVAeJvPuCvPy4gFz+D04fgy/1wvB9Zmi69bcqseWTNDShhyhtjm7K3j7mDWzl76STjk2eY/O4YsrsPeWcDMnW76nJeKly5u+v3VITGEvZvJBt8nZEjA4x9MUj+0TZkn46MjpNMjVVNyUuFzyaUMhUd7OToLrKPt8PRnfDpG8jhNxVvIZ/vRObvr3b5eYQrsM2y09DqOAQ6EUH1Z2FHbf33z1e4xpYbs9qqwrayf9r3MXNrmzyIDk39AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Metatags example",
            "title": "Metatags example",
            "src": "/static/c13c77ae362fadfbc5e0b86d2e9b1dcc/f570d/metatags-example.png",
            "srcSet": ["/static/c13c77ae362fadfbc5e0b86d2e9b1dcc/0783d/metatags-example.png 240w", "/static/c13c77ae362fadfbc5e0b86d2e9b1dcc/782f4/metatags-example.png 480w", "/static/c13c77ae362fadfbc5e0b86d2e9b1dcc/f570d/metatags-example.png 960w", "/static/c13c77ae362fadfbc5e0b86d2e9b1dcc/8d0ff/metatags-example.png 1440w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Tip: Use `}<a parentName="p" {...{
          "href": "https://metatags.io/"
        }}>{`metatags.io`}</a>{` to see how your cover would look like on social media.`}</p>
    </blockquote>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Export the relevant artboards into the folder you created in step 1. The sketch file and the exported images should always be in the same folder. `}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Please note that the horizontal and square topic illustrations should be exported as `}<inlineCode parentName="em">{`svg`}</inlineCode>{` while the cover should be exported as an image at 2x the size.`}</em></p>
    </blockquote>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Upload the svgs and image to DatoCMS, Twitter, Facebook etc.`}</li>
    </ol>
    <h3>{`How to work with story promotional templates`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make a new folder in `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/0dndu6h0n4q28zb/AADgIdKe0UYegTqQ0nsMWZi2a?dl=0"
          }}><inlineCode parentName="a">{`Stories`}</inlineCode></a>{` with the same name as your story. Please use hyphenation (e.g. `}<inlineCode parentName="p">{`2018-in-review`}</inlineCode>{`).  `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy `}<inlineCode parentName="p">{`story-promotional-template.sketch`}</inlineCode>{` from `}<a parentName="p" {...{
            "href": "https://www.dropbox.com/sh/forjo35pp96diuf/AABaTDOOu_lpX1BWzUYlPZDca?dl=0"
          }}><inlineCode parentName="a">{`Templates/Promotional templates/`}</inlineCode></a>{` into the folder you created in step 1.   `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use the `}<inlineCode parentName="p">{`story-cover`}</inlineCode>{` artboard for the story cover. You can also copy paste this or any of the other artboards to create visuals for the story.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Export the relevant artboards as images at 2x the size into the folder you created in step 1. The sketch file and the exported images should always be in the same folder.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Upload the images to DatoCMS, Twitter, Facebook etc.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      